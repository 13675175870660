var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isDataLoaded)?_c('div',{staticClass:"p-4 bg-white rounded-lg"},[_c('DataTable',{ref:"dataTable",attrs:{"table_state":_vm.table_state,"store_names":_vm.store_names,"addComponent":_vm.componentForAdding,"editComponent":_vm.componentForEditing,"delete_url":_vm.delete_url,"urls":_vm.urls},scopedSlots:_vm._u([{key:"rowActionsDropdown",fn:function({
        updateTable,
        item,
        pageLoader,
        permissions,
      }){return [(permissions('update'))?_c('div',[_c('v-btn',{staticClass:"w-100 text-left",class:!item.extra_data.detail.actions.copy ? 'text-muted' : 'text-dark',attrs:{"disabled":!item.extra_data.detail.actions.copy,"text":""},on:{"click":() => {
              _vm.actionCopy(item);
            }}},[_vm._v("Copy")])],1):_vm._e()]}}],null,false,4026161720)})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }