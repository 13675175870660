<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    persistent
    transition="dialog-bottom-transition"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins h-100">
      <!--begin::Modal header-->
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">Select Column</h3>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-8 py-4">
        <!--begin::Body-->
        <form id="createForm" class="row h-100 mx-0 px-0 w-100">
          <div class="col-6">
            <h3 class="py-2">Available Columns</h3>
            <div class="" style="overflow-y: scroll; max-height: 79vh; ; height: 100%;">
              <draggable
                class="list-group bg-lighten pa-4"
                :list="availableColumnsArray"
                group="columns"
              >
                <div
                  class="list-group-item h4 px-4 my-1 mx-1 row align-items-center justify-start"
                  v-for="element in availableColumnsArray"
                  :key="element.value"
                >
                  <div class="px-4 py-2">{{ element.text }}</div>
                  <div class="my-0 py-0">
                  <v-text-field
                    v-model="element.label"
                    label="Label"
                    clearable
                    hide-details
                    outlined
                    dense
                    disabled
                  ></v-text-field>
                </div>
                </div>
              </draggable>
            </div>
          </div>
          <div class="col-6">
            <h3 class="py-2">Selected Columns</h3>
            <div class="" style="overflow-y: scroll; max-height: 79vh; height: 100%;">
              <draggable
                class="list-group bg-lighten pa-4"
                :list="selectedColumnsArray"
                group="columns"
              >
                <div
                  class="list-group-item h4 px-4 my-1 mx-1 row align-items-center justify-start"
                  v-for="element in selectedColumnsArray"
                  :key="element.value"
                >
                  <div class="px-4 py-2">{{ element.text }}</div>
                  <div class="my-0 py-0">
                    <v-text-field
                      v-model="element.label"
                      label="Label"
                      dense
                      outlined
                      clearable
                      hide-details
                    ></v-text-field>
                  </div>
                </div>
              </draggable>
            </div>
          </div>
        </form>
        <!--end::Body-->

        <!--begin::Actions-->
        <div class="mb-4 pt-10 d-flex flex-grow-1 align-end justify-center">
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="resetCreateForm"
          >
            Clear
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitInsertionForm"
          >
            Submit
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import draggable from "vuedraggable";

export default {
  validations: {
    formData: {
      organization_id: { required },
      customer_id: { required },
      module_id: { required },
    },
  },
  components: { draggable },
  name: "InsertTable",
  props: {
    availableColumns: {
      required: true,
      type: Array,
    },
    selectedColumns: {
      required: true,
      type: Array,
    },
    insertTableToTemplate: {
      required: true,
      type: Function,
    },
  },
  data: () => ({
    dialog: false,
    availableColumnsArray: [],
    selectedColumnsArray: [],
  }),
  computed: {},
  methods: {
    toggleModal() {
      if (!this.dialog) {
        this.resetCreateForm();
      } else {
        this.availableColumnsArray = [];
        this.selectedColumnsArray = [];
      }
      this.dialog = !this.dialog;
    },
    submitInsertionForm() {
      this.insertTableToTemplate(this.selectedColumnsArray);
      this.toggleModal();
    },
    resetCreateForm() {
      this.availableColumnsArray = JSON.parse(
        JSON.stringify(this.availableColumns)
      );
      this.selectedColumnsArray = JSON.parse(
        JSON.stringify(this.selectedColumns)
      );
    },
  },
};
</script>
<style></style>
